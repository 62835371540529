'use client'

import { useEffect, useState } from 'react'
import { ContentfulLivePreview } from '@contentful/live-preview'

import { Container } from '../../components/container'
import { cn } from '../../utils/cn'
import contentfulImage from '../../utils/contentful-image'
import { RichText } from '../helper-components/rich-text'

export interface AffiliateHeroProps {
  id: string
  fieldId: string
  title: any
  image: {
    alt: string
    href: string
    pixelatedUrl?: string
  }
  logo?: {
    alt: string
    href: string
    pixelatedUrl?: string
  }
  color: string
}

export const AffiliateHero = (props: AffiliateHeroProps) => {
  const [src, setSrc] = useState(props.image.pixelatedUrl || props.image.href)

  useEffect(() => {
    const loadImage = async () => {
      if (!props.image.pixelatedUrl) return

      const img = new Image()
      img.src = src as string

      img.onload = () => setSrc(src)
    }

    loadImage()
  }, [props.image.pixelatedUrl, src])

  return (
    <div
      role="presentation"
      className="relative"
      data-testid="background"
      style={{
        backdropFilter: src === props.image.href ? '' : 'blur(10px)',
        background: `linear-gradient(0deg, rgba(84, 84, 102, 0.6), rgba(84, 84, 102, 0.6)), url(${props.image.href})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'backdrop-filter 0.2s ease-out',
        borderBottom: '35px solid ' + props.color,
      }}
      {...ContentfulLivePreview.getProps({
        entryId: props.id,
        fieldId: 'topBannerImage',
        locale: 'en-US',
      })}
    >
      {/* remember that bg-primary will be overridden by the university color, see university-setter.tsx component */}
      <div className="bg-primary absolute inset-0 opacity-60" />
      <Container
        className={cn('relative z-10 flex min-h-[40vw] flex-col py-6')}
      >
        <section
          className={cn(
            'flex max-w-[70%] flex-1 flex-col items-start justify-center gap-6'
          )}
        >
          {props.logo?.href && (
            <img
              src={contentfulImage(props.logo.href)}
              alt={props.logo.alt}
              {...ContentfulLivePreview.getProps({
                entryId: props.id,
                fieldId: 'topBannerLogo',
                locale: 'en-US',
              })}
            />
          )}
          <h1
            className="text-white"
            style={{ fontSize: 'max(min(5rem, 5vw), 3rem)' }}
            {...ContentfulLivePreview.getProps({
              entryId: props.id,
              fieldId: 'topBannerText',
              locale: 'en-US',
            })}
          >
            <RichText json={props.title?.json} links={props.title?.links} />
          </h1>
        </section>
      </Container>
    </div>
  )
}
