'use client'

import { useEffect } from 'react'
import { PageAffiliate } from '@cerifi/contentful/__generated/graphql.types'
import { useUniversityStore } from '@cerifi/state/university.store'
import {
  colorChecker,
  darkenColorUntilAllowed,
} from '@cerifi/ui/utils/color-helpers'

interface Props {
  config: PageAffiliate
}

export const UniversitySetter = ({ config }: Props) => {
  const [universityEntryId, setUniversityEntryId] = useUniversityStore(
    (state) => [state.universityEntryId, state.setUniversityEntryId]
  )

  useEffect(() => {
    const chooseUniversityColor = () => {
      let color: string

      // is theme allowed?
      if (
        colorChecker.isLevelAA(config.theme?.value || '#333333', '#FFFFFF', 12)
      ) {
        color = config.theme?.value || '#333'
      } else if (
        colorChecker.isLevelAA(
          config.secondaryTheme?.value || '#333333',
          '#FFFFFF',
          12
        )
      ) {
        // is secondary color allowed
        color = config.secondaryTheme?.value || '#333333'
      } else {
        // all their colors are not accessible, darkening the primary color they picked until it is allowed.
        color = darkenColorUntilAllowed(config.theme?.value || '#333333')
      }

      document.documentElement.style.setProperty('--university-color', color)
    }

    if (universityEntryId !== config.sys.id) {
      chooseUniversityColor()
      setUniversityEntryId(config.sys.id)
    }
  }, [
    config.sys.id,
    setUniversityEntryId,
    universityEntryId,
    config.theme?.value,
    config.secondaryTheme?.value,
  ])

  return null
}
